import React from 'react'
import { css } from '@emotion/react'

import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { RebootusHead } from '@/components/rebootus/rebootusHead'
import { HeaderNav } from '@/components/section/header/header-nav'
import { headerNavList } from '@/constants/header/rebootus'
import { FooterBottom } from '@/components/section/footer/footer-bottom'
import { footerBottomList } from '@/constants/footer/rebootus'
import header_rebootus_logo from '@/images/rebootus/logo-corporate.svg'

export default function Policy(): JSX.Element {
  return (
    <>
      <RebootusHead title="株式会社Relicホールディングス" url="company" />
      <HeaderNav
        css={cssHeaderNav}
        url="/group/rebootus"
        imgSrc={header_rebootus_logo}
        alt="Rebootus"
        navList={headerNavList}
        gradientColor={[color.yellow1, color.green1]}
        btnFontStyleCss={cssBtnFontStyle}
        arrowFillColor={color.black2}
        headerLogoCss={cssHeaderLogoSize}
        breakpoint={768}
      />
      <section
        css={css`
          padding-top: 80px;
          margin-bottom: 24px;
        `}
      >
        <div css={cssLyContentsBody}>
          <h2>プライバシーポリシー</h2>
          <div
            css={css`
              margin-top: 24px;
            `}
          >
            <div css={cssMb}>
              株式会社Rebootus(Reboo+)(以下、「当社」といいます）では、お客様の個人情報に最大限の注意を払っています。お客様の個人情報を保護することが、当社の事業活動の基本であると共に、当社の社会的責務であると考えております。
              <br />
              当社は、責任をもってお客様の個人情報を保護するため、個人情報の保護に関し以下のとおり定め、社内体制の構築、施策の実施・維持、それらの継続的な改善を行っています。
            </div>
            <div css={cssMb}>
              <h2>適用範囲</h2>
              本プライバシーポリシーは、当社に関する全てのサービス（以下、「当社サービス」といいます）及び採用などの企業活動全般に適用されます。各サービス及び採用等の特設ページごとのプライバシーポリシーまたは利用規約等（以下、「個別規約」といいます）において個別に個人情報の取扱いを規定する場合は、個別規約も適用されます。本プライバシーポリシーと個別規約において矛盾・抵触が発生する場合は個別規約が優先されます。
            </div>
            <div css={cssMb}>
              <h2>お客様の個人情報の収集について</h2>
              当社では、当社サービスをご利用いただくために、お客様の個人情報をご提供いただくことがあります。原則として、お客様にお断りなく、お客様ご自身から個人情報を収集することはございません。当社がお客様から個人情報をご提供いただく場合には、その利用目的を明示した上で個人情報の収集をいたします。（ただし、お客様から個人情報をご提供いただけない場合には、ご利用できない当社サービスがございますことをあらかじめご了承ください。）
            </div>
            <div css={cssMb}>
              <h2>お客様の個人情報の利用について</h2>
              当社は、収集した個人情報を、以下の目的のほか、当社サービスにおいてあらかじめお客様に明示した目的のために利用いたします。明示した目的の範囲を超えてお客様の個人情報を利用する必要が生じた場合には、その旨お客様にお知らせいたします。
              <br />
              当社サービスのご利用者の情報
              <ul css={cssMb}>
                <li>・利用登録に関する各種手続</li>
                <li>
                  ・ご利用いただく当社サービスのご提供及び関連する情報のご案内
                </li>
                <li>・当社におけるサービスの開発・改善のための分析</li>
                <li>
                  ・当社サービス及びそれに関連する情報に関するアンケート・調査等のご依頼
                </li>
                <li>・お問い合わせ等への対応</li>
                お取引先の役職員の情報
                <li>・お取引先の管理</li>
                <li>・当社サービス及びそれに関連する情報のご案内</li>
                <li>
                  ・当社サービス及びそれに関連する情報に関するアンケート・調査等のご依頼
                </li>
                <li>・業務上のご連絡及び契約の履行</li>
                <li>・お問い合わせ等への対応</li>
              </ul>
            </div>
            <div css={cssMb}>
              <h2>お客様の個人情報の第三者への提供について</h2>
              当社は、お客様の個人情報について、以下の各号のいずれかに該当する場合を除き、第三者に提供することはありません。
              <ul css={cssMb}>
                <li>・お客様の事前の同意を得た場合。</li>
                <li>
                  ・合併その他の事由による事業の承継に伴って個人情報が提供される場合。
                </li>
                <li>
                  ・生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき。
                </li>
                <li>
                  ・国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき。
                </li>
                <li>・その他法令に基づく場合。</li>
              </ul>
            </div>
            <div css={cssMb}>
              <h2>委託先の監督</h2>
              当社は、利用目的の達成に必要な範囲内において、お預かりした個人情報の取扱いを第三者に委託する場合があります。これらの第三者は、個人情報の取扱いにつき、十分なセキュリティ水準にあることを確認の上で選定し、契約等を通じて、必要かつ適切な監督を行います。
            </div>
            <div css={cssMb}>
              <h2>個人情報の管理について</h2>
              当社は、お客様の個人情報を適切、厳重に管理し、お客様の個人情報への不正なアクセスや情報の紛失、破壊、改ざん及び漏洩等が起きないよう、予防及び安全対策を講じております。
            </div>
            <div css={cssMb}>
              <h2>業務運営について</h2>
              当社は、個人情報の保護に関する法律及びその関連法令並びにその他の規範に則った業務運営に努めてまいります。
            </div>
            <div css={cssMb}>
              <h2>継続的改善について</h2>
              当社は、個人情報の保護及び取扱いについて、継続的改善に努めてまいります。
            </div>
            <div css={cssMb}>
              <h2>第三者のウェブサイト、アプリケーションとのリンクについて</h2>
              お客様がアカウントをお持ちのソーシャルネットワークサービス（SNS）や他社のサービスとの相互連携のため、当社サービスからログイン、投稿などができる機能の組込みを行うことがあります。
            </div>

            <div css={cssMb}>
              <h2>Cookieの使用について</h2>
              当社のウェブサイト及び当社サービスでは、お客様へのサービス向上、広告配信及び統計データの取得などの用途でCookieを使用しております。
              <br />
              ※Cookieには氏名、住所、電話番号などの個人を識別する情報は含まれません
              <br />
              Cookieを使用した情報収集を受けたくない場合には、ウェブブラウザの設定でCookieの受け入れを拒否することができます。なお、Cookieの受け入れを拒否した場合、当社のウェブサイト及び当社サービスの一部をご利用いただけなくなることがございますのでご了承ください。
              <br />
              また、当社は、お客様による当社のウェブサイト及び当社サービスのご利用状況を調査・分析等をするために「Google
              Analytics」を用い統計的な利用情報を取得しています。Google
              Analyticsでは、Cookieを使用して個人を識別する情報を含まない情報を収集します。Google
              Analyticsにて情報が収集、処理される仕組みは、以下「Googleポリシーと規約」をご確認ください。
              <br />
              https://www.google.com/policies/privacy/partners/
            </div>
            <div css={cssMb}>
              <h2>個人情報の開示等</h2>
              当社は、お客様から自らの個人情報の開示、訂正、追加、削除、利用停止、消去または第三者提供の停止等（以下、これらを総称して「開示等」といいます）の請求を受けた場合は、当社所定の手続きに基づき、ご本人であることを確認させていただいた上で、速やかに対応いたします。ただし、法令の定めにより当社が開示等の義務を負わない場合はこの限りではありません。なお、個人情報の利用停止や消去等によって、当社サービスのご提供ができなくなることがございますのでご了承ください。
            </div>
            <div css={cssMb}>
              <h2>お問い合わせ窓口</h2>
              当社における個人情報の取扱いに関するご意見・ご相談等につきましては、下記窓口へご連絡ください。
              <ul css={cssMb}>
                <li>
                  住所：〒150-6008
                  東京都渋谷区恵比寿4-20-3恵比寿ガーデンプレイスタワー8F
                </li>
                <li>株式会社Rebootus(Reboo+)</li>
                <li>個人情報取扱担当</li>
                <li>連絡先：info@relic.co.jp</li>
              </ul>
              当社では、お客様の個人情報の保護を図るために、または法令等の変更に対応するために、本プライバシーポリシーの内容を変更することがあります。変更の際には、当社ウェブサイトへの掲載や電子メール等によりお知らせいたします。
            </div>
            
          </div>
        </div>
      </section>
      <FooterBottom footerBottomList={footerBottomList} />
    </>
  )
}

const cssHeaderNav = css`
  padding: 15px 16px;
  font-family: 'Jost', 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium',
    YuGothic, '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'メイリオ',
    'Hiragino Kaku Gothic Pro', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;
  box-shadow: 0 3px 6px ${color.gray1};
  ${mq.pc} {
    box-shadow: 0 0 16px ${color.gray1};
  }
`

const cssHeaderLogoSize = css`
  width: 90px;
  ${mq.mid} {
    width: 195px;
  }
`

const cssBtnFontStyle = css`
  font-weight: 600;
  color: ${color.black2};
`

const cssLyContentsBody = css`
  padding: 112px 16px 60px;
  ${mq.pc} {
    max-width: 1080px;
    padding: 180px 16px 120px;
    margin: 0 auto;
  }
`

const cssMb = css`
  margin-bottom: 24px;
`